import React, { Fragment, useState, useEffect, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { useCampaignContext } from "../../contexts/CampaignContext";
import Input from "../Atoms/Input";
import Loading from "../Atoms/Loading";
import Button from "../Atoms/Button";
import IconCollection from "../../utilities/IconMap";
import { PencilIcon as PencilIconOutline, CheckIcon, XMarkIcon,ChevronDownIcon } from "@heroicons/react/24/outline";
import useUserInformation from "../../hooks/useUserInformation";
import { PERMISSIONS } from "../../utilities/Constants";

const Breadcrumbs = lazy(() => import("../Infrastructure/Breadcrumbs"));

const publishingOptions = [
  {
    name: "Published",
    description: "This job posting can be viewed by anyone who has the link.",
    current: true,
  },
  {
    name: "Draft",
    description: "This job posting will no longer be publicly accessible.",
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PageHeader = ({ pageTitle, actions, subheader, tabs, tabChange, titleEditable, campaignId }) => {
  const campaignContext = useCampaignContext();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [statePageTitle, setPageTitle] = useState(pageTitle);
  const [selected, setSelected] = useState(publishingOptions[0]);
  const { useUpdateSession } = campaignContext;

  const userInfo = useUserInformation(campaignId);

  const { mutate: UpdateSession } = useUpdateSession();

  const handleActionClick = (href) => {
    navigate(href);
  };

  const StandaloneActions = () => {
    return (
      <>
        {actions.length > 0 &&
          actions.map((action, index) => {

            if(action.visibleTo != userInfo?.Permissions)
              return null;

            const { name, href, onClick, icon, id } = action;
            const Icon = IconCollection.find(x => x.Name == icon).Icon;
            if (action.group === false)
              return (                
                  <span className="block" key={name}>
                    <Button
                      id={id}
                      icon={Icon}                      
                      textColor="text-texttertiary"
                      label={name}
                      onClick={() =>
                        onClick ? onClick() : handleActionClick(href)
                      }
                    />
                  </span>                
              );
          })}
      </>
    );
  };

  const GroupActions = () => {
    let groupActions = [];
    if (actions.length > 0) {
      groupActions = actions.filter((action) => action.group === true);
    }

    return (
      <>
        {groupActions.length > 0 && (
          <div className="isolate inline-flex rounded-md shadow-sm sm:shadow-none ">
            <span className="hidden md:inline-flex  sm:shadow-sm">
              {groupActions.map((action, index) => {

              if(action.visibleTo != userInfo?.Permissions)
                return null;

                let rounding = "";
                const { name, href, onClick, icon } = action;
                const Icon = IconCollection.find(x => x.Name == icon).Icon;

                if (index === 0) {
                  rounding = "rounded-l-md";
                } else if (index === groupActions.length - 1) {
                  rounding = "rounded-r-md";
                }

                return (
                  <span key={index} className="inline-flex sm:shadow-sm">
                    <Button
                      rounding={rounding}
                      bgColor="bg-background"
                      border="border-textprimary"
                      hoverBgColor="bg-grayhover"                      
                      textColor="text-textprimary"
                      groupIndex={index}
                      icon={Icon}
                      label={name}
                      onClick={() =>
                        onClick ? onClick() : handleActionClick(href)
                      }
                    />
                  </span>
                );
              })}
            </span>

            {/* Dropdown */}
            <Menu as="div" className="relative block sm:shadow-sm md:hidden">
              <div>
                <Menu.Button className="relative inline-flex items-center border border-gray-300 bg-background px-4 py-2 text-textprimary text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 rounded-md">
                  <span className="sr-only sm:hidden">More</span>
                  <span className="xs:inline">More</span>
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-gray-400 sm:ml-2 sm:-mr-1"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-background text-textprimary shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {groupActions.map((action, index) => (
                      <Menu.Item key={index}>
                        <a
                          onClick={() =>
                            action.onClick
                              ? action.onClick()
                              : handleActionClick(action.href)
                          }
                          className={classNames(
                            "bg-gray-100 text-gray-900 block px-4 py-2 text-sm md:hidden"
                          )}
                        >
                          {action.name}
                        </a>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
      </>
    );
  };

  const handleUpdateTitle = () => {
    UpdateSession(statePageTitle);
    setIsEditing(false);
  };

  useEffect(() => {
    setPageTitle(pageTitle);
  }, [pageTitle]);

  return (
    <header
      className={`bg-background pt-2 px-6 md:pt-4 lg:px-8 border-b-2 border-border flex-col lg:items-center lg:justify-between ${
        !tabs && "pb-4"
      }`}
    >
      <div className="flex flex-row flex-1 flex-wrap items-center">
        <div className="flex flex-col flex-1 w-full gap-2">
          <Suspense fallback={<Loading />}>
            <Breadcrumbs />
          </Suspense>
          <h1 className="flex shrink mt-2 text-xl font-bold leading-7 text-primary sm:text-3xl sm:tracking-tight">
            {titleEditable ? (
              isEditing ? (
                <div className="flex flex-row gap-4 shrink w-full">
                  <Input
                    value={statePageTitle}
                    type="text"
                    name={"SessionTitle"}
                    onChange={(e) => setPageTitle(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") handleUpdateTitle();
                    }}
                    maxLength={60}
                  />
                  <CheckIcon
                    className="w-[20px] min-w-[20px] cursor-pointer"
                    onClick={handleUpdateTitle}
                  />
                  <XMarkIcon
                    onClick={() => {
                      setPageTitle(pageTitle);
                      setIsEditing(false);
                    }}
                    className="w-[20px] min-w-[20px] cursor-pointer"
                  />
                </div>
              ) : (
                <div className="flex flex-row gap-2 shrink">
                  <div className="flex shrink break-all">
                    {statePageTitle}
                  </div>
                  <PencilIconOutline
                    className="w-[20px] min-w-[20px] cursor-pointer"
                    onClick={() => setIsEditing(true)}
                  />
                </div>
              )
            ) : (
              statePageTitle
            )}
          </h1>

          {/* Subheader with Icons */}
          <div className="flex flex-col gap-2 sm:mt-0 sm:flex-row sm:flex-wrap">
            {subheader &&
              subheader.map((item) => {
                const { name, data, icon } = item;
                const Icon =
                  IconCollection.find((x) => x.Name == icon).Icon || null;

                return (
                  <div
                    key={name}
                    className="flex items-center text-sm text-textsecondary"
                  >
                    <div className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400">
                      <Icon>{Icon}</Icon>
                    </div>
                    {name} {data}
                  </div>
                );
              })}
          </div>
        </div>

        <div className="flex flex-1 justify-end">
          {actions ? (
            <div className="mt-5 h-min flex gap-3 lg:mt-0 lg:ml-4">
              <StandaloneActions />
              <GroupActions />
            </div>
          ) : null}
        </div>
      </div>

      {tabs ? (
        <div className="mt-3">
          <div className="sm:hidden mb-2">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary bg-background text-textprimary"
              defaultValue={tabs.find((tab) => tab.current).name}
              onChange={(e) => tabChange(e.target.value)}
            >
              {tabs.map((tab) => {
                if (tab.mobileDisabled) return;

                return (
                  <option key={tab.name} value={tab.value}>
                    {tab.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="hidden sm:block ">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  onClick={tab.onClick}
                  className={classNames(
                    tab.current
                      ? "border-primary"
                      : "border-transparent hover:border-accent hover:text-accent",
                    "whitespace-nowrap border-b-2 px-1 pb-3 pt-1 text-sm font-medium cursor-pointer gap-2 flex"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  <span
                    className={classNames(
                      tab.current ? "text-primary" : "text-textprimary"
                    )}
                  >
                    {tab.name}
                  </span>
                  {tab.new && (
                    <span className="inline-flex items-center rounded-md bg-secondary px-1.5 py-0.5 text-xs font-medium text-textprimary border border-text-primary">
                      New!
                    </span>
                  )}
                </a>
              ))}
            </nav>
          </div>
        </div>
      ) : null}
    </header>
  );
};

export default PageHeader;
